import { Fragment } from "react";

import { Card, CardBody } from "reactstrap";

import DataTables from "Components/DataTables/DataTable";
import EditItem from "Components/EditItem";
import ModalApp from "Components/ModalApp";
import AddItem from "Components/AddItem";

import { API, COLUMNS, CREATE_ITEMS, EDIT_ITEMS } from "./data/rules";

import { CREATE_TARIFF, EDIT_TARIFF } from "Constant";
import { Group } from "@mantine/core";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";

// import CreateRules from "./CreateRules";

const RulesTable = ({ triffCode }) => {
  const list = API.list(triffCode);

  const ACTION = {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 50,
    Cell: ({ row }) => {
      const { code, active, label } = row.original;
      return (
        <Group gap="xs">
          <ActiveBtn patch={API.edit + code} state={active} />
          <EditBtn
            modalType={"editRules"}
            read={API.get + code}
            extra={label}
          />
        </Group>
      );
    },
  };

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <DataTables
            modalType={"createRules"}
            newColumns={[...COLUMNS, ACTION]}
            link={list}
          />
        </CardBody>
      </Card>

      <ModalApp type="createRules" title={CREATE_TARIFF}>
        <AddItem addURL={API.create} items={CREATE_ITEMS} />
        {/* <CreateRules addURL={API.create} items={CREATE_ITEMS} /> */}
      </ModalApp>

      <ModalApp type="editRules" title={EDIT_TARIFF}>
        <EditItem itemsUpdate={EDIT_ITEMS} />
      </ModalApp>
    </Fragment>
  );
};

export default RulesTable;
