import FilterSelectBar from "Components/FilterInputs/FilterSelectBar";
import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import ReadSheetBtn from "Components/MantineDataTableBtns/ReadSheetBtn";
import StatusColumn from "Components/MantineDataTableBtns/StatusColumn";
import { CLIENT, DRIVERS, SERIAL_NUMBER, STATUS } from "Constant";
import { createdAt, statusObj, updatedAt } from "Constant/objs";
import { getCodeLabel, getTitleCodeLabel } from "helper/getOptions";

// Urls
export const API = {
  list: "/back/badges",
  GET_BADGES_DRIVERS: "/back/badges/drivers",
  GET_BADGES_FITTERS: "/back/badges/fitters",
  Serial: "/back/badges/serials",
  create: "/back/badge",
  get: "/back/badge/",
  patch: "/back/badge/",
};

// Columns
export const COLUMNS = [
  {
    name: SERIAL_NUMBER,
    selector: "serial_number",
    enableColumnFilter: false,
  },

  // {
  //   name: DRIVERS,
  //   selector: "driver_name",
  //   enableColumnFilter: false,
  // },

  {
    accessorFn: (originalRow) => originalRow.complete_name,
    id: "client",
    header: CLIENT,
    size: 300,

    Cell: ({ row }) => {
      const { client, client_code } = row.original;
      const path = `/client/${client_code}`;

      return <ReadSheetBtn read={path} title={client} />;
    },
  },

  statusObj("material"),

  createdAt,
  updatedAt,
  {
    id: "actions",
    size: 10,
    Cell: ({ row }) => {
      const { code, active } = row.original;
      return <ActiveBtn patch={API.patch + code} state={active} />;
    },
  },

  // {
  //   name: "Taxi",
  //   cell: (row) => <ReadPageBtn read={`/taxi/${row.code}`} />,
  //   button: "true",
  // },
  // {
  //   name: "Client",
  //   cell: (row) => <ReadPageBtn read={`/client/${row.code}`} />,
  //   button: "true",
  // },
  // {
  //   name: "Taximeter",
  //   cell: (row) => <ReadPageBtn read={`/taximeter/${row.code}`} />,
  //   button: "true",
  // },
];

// Search
export const SEARCH_ITEMS = [
  {
    name: "status",
    title: "status",
    inputForm: "SelectApiInput",
    required: false,

    optionsApi: "/back/statuses/material",
    getOptions: getCodeLabel,
  },
];
// Form
export const CREATE_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/badge",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "rfid",

    placeholder: "rfid",
    name: "rfid",
    inputForm: "SimpleInput",
  },
];
export const EDIT_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/badge",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "rfid",

    placeholder: "rfid",
    name: "rfid",
    inputForm: "SimpleInput",
  },
];
