import FilterSelectBar from "Components/FilterInputs/FilterSelectBar";
import { createdAt, statusObj, updatedAt } from "Constant/objs";
import {
  getCodeLabel,
  getOptionNumber,
  getTitleCodeLabel,
} from "helper/getOptions";
import StatusColumn from "Components/MantineDataTableBtns/StatusColumn";

// Urls
export const API = {
  list: "/back/styluses",
  choice: "/back/styluses/choices",
  Serial: "/back/styluses/serials",
  create: "/back/stylus",
};

// Columns
export const COLUMNS = [
  statusObj("material"),
  {
    name: "Number",
    selector: "serial_number",
  },
  createdAt,
  updatedAt,
];

// Search
export const SEARCH_ITEMS = [
  {
    name: "status",
    title: "status",
    inputForm: "SelectApiInput",
    required: false,
    optionsApi: "/back/statuses/material",
    getOptions: getCodeLabel,
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/stylus",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Amount",
    name: "amount",
    inputForm: "SelectInput",
    options: getOptionNumber(1, 100),
    number: true,
  },
];

export const EDIT_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/stylus",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Amount",
    name: "amount",
    inputForm: "SelectInput",
    options: getOptionNumber(1, 100),
    number: true,
  },
];
